/* Trees  */
.mat-tree {
  @apply bg-transparent;
}

.mat-tree-node {
  min-height: 32px !important;
}

.struct-tree-progress-bar {
  height: 2px !important;
}
