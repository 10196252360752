:root {
  @include mat.tooltip-overrides(
    (
      container-shape: theme('spacing.1'),
      supporting-text-size: theme('fontSize.xs'),
      supporting-text-line-height: theme('lineHeight.4'),
      container-color: theme('colors.gray.800'),
    )
  );
}

.mat-mdc-tooltip-surface {
  opacity: 0.8;
}
