:root {
  @include mat.slide-toggle-overrides(
    (
      // track-height: 22px,
        // track-width: 44px,
        track-outline-width: 0px,
      track-outline-color: theme('colors.gray.200'),
      disabled-unselected-track-outline-color: theme('colors.gray.100'),
      handle-surface-color: theme('colors.white'),
      handle-shape: theme('spacing.1'),
      track-shape: theme('spacing.1'),
      // with-icon-handle-size: 22px,
        unselected-track-color: theme('colors.gray.200'),
      unselected-hover-track-color: theme('colors.gray.200'),
      unselected-handle-color: theme('colors.white'),
      unselected-hover-handle-color: theme('colors.white'),
      unselected-focus-handle-color: theme('colors.white'),
      unselected-pressed-handle-color: theme('colors.white'),
      unselected-icon-size: 0px,
      selected-track-color: theme('colors.blue.500'),
      selected-handle-color: theme('colors.white'),
      selected-hover-handle-color: theme('colors.white'),
      selected-focus-handle-color: theme('colors.white'),
      selected-pressed-handle-color: theme('colors.white'),
      selected-icon-size: 0px,
      handle-elevation-shadow: none
    )
  );
}

.mat-mdc-slide-toggle {
  @apply w-full h-full flex items-center;

  .mdc-label {
    @apply pl-2;
  }

  .struct-slide-toggle-on {
    @apply hidden absolute left-1 top-0 w-[20px] h-full items-center justify-center text-[9px] font-semibold text-blue-50 animate-out slide-out-to-bottom-4;
  }

  &.mat-mdc-slide-toggle-checked {
    .struct-slide-toggle-on {
      @apply flex animate-in slide-in-from-bottom-4;
    }
  }
}

// .mat-mdc-slide-toggle {
//   .mdc-label {
//     @apply absolute opacity-50 text-xs font-medium text-white;
//   }

//   &.mdc-switch:enabled {
//     .mdc-label {
//       @apply opacity-100;
//     }
//   }
// }
