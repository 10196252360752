:root {
  @include mat.list-overrides(
    (
      list-item-one-line-container-height: 28px,
    )
  );
}

/* Lists */
.mat-mdc-list-base {
  --mdc-list-list-item-container-shape: 0.375rem !important;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 24px !important;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;

  .mdc-list-item.mdc-list-item--with-two-lines .mdc-list-item__start {
    // align-self: unset;
    // margin-top: unset;
  }

  .mdc-list-item--with-leading-icon .mdc-list-item__start {
    margin-right: 16px;
  }
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: theme(colors.blue.500) !important;
  --mdc-checkbox-selected-hover-icon-color: theme(colors.blue.600) !important;
  --mdc-checkbox-selected-icon-color: theme(colors.blue.500) !important;
  --mdc-checkbox-selected-pressed-icon-color: theme(colors.blue.500) !important;
  --mdc-checkbox-unselected-focus-icon-color: theme(colors.gray.800);
  --mdc-checkbox-unselected-hover-icon-color: theme(colors.gray.800);
  --mdc-checkbox-unselected-icon-color: theme(colors.gray.700) !important;
  --mdc-checkbox-unselected-pressed-icon-color: theme(colors.gray.700) !important;
  --mdc-checkbox-selected-focus-state-layer-color: theme(colors.blue.500) !important;
  --mdc-checkbox-selected-hover-state-layer-color: theme(colors.blue.500) !important;
  --mdc-checkbox-selected-pressed-state-layer-color: theme(colors.blue.500) !important;
  --mdc-checkbox-unselected-focus-state-layer-color: transparent !important;
  --mdc-checkbox-unselected-hover-state-layer-color: transparent !important;
  --mdc-checkbox-unselected-pressed-state-layer-color: transparent !important;

  --mdc-list-list-item-label-text-weight: 500;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: var(--mdc-list-list-item-one-line-container-height, 48px) !important;
}

.mdc-list-item--with-leading-avatar .mdc-list-item__start {
  margin-left: theme('spacing.2') !important;
  margin-right: theme('spacing.2') !important;
}

.mat-mdc-list-base {
  .mat-mdc-list-item {
    /* height: unset !important; */
    @apply flex items-center mb-2 p-0 rounded cursor-pointer border border-gray-200 hover:border-blue-300;
  }

  .mat-mdc-list-item > .mat-mdc-focus-indicator {
    display: none !important;
  }

  .mat-mdc-list-item-interactive::before {
    display: none !important;
  }

  .mat-mdc-list-item[aria-selected='true'] {
    @apply border-blue-500 bg-blue-50;
  }

  .mdc-list-item__content {
    @apply flex flex-col items-start justify-center pl-2;

    &:before {
      display: none !important;
    }
  }

  .mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
    display: none !important;
  }
}

.mat-list-light {
}

.struct-filter-list {
  @include mat.list-overrides(
    (
      list-item-one-line-container-height: 28px,
    )
  );

  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: theme(colors.blue.500) !important;
  --mdc-checkbox-selected-hover-icon-color: theme(colors.blue.500) !important;
  --mdc-checkbox-selected-icon-color: theme(colors.blue.500) !important;
  --mdc-checkbox-selected-pressed-icon-color: theme(colors.blue.500) !important;
  --mdc-checkbox-unselected-focus-icon-color: theme(colors.gray.800);
  --mdc-checkbox-unselected-hover-icon-color: theme(colors.gray.800);
  --mdc-checkbox-unselected-icon-color: theme(colors.gray.700) !important;
  --mdc-checkbox-unselected-pressed-icon-color: theme(colors.gray.700) !important;
  --mdc-checkbox-selected-focus-state-layer-color: theme(colors.blue.500) !important;
  --mdc-checkbox-selected-hover-state-layer-color: theme(colors.blue.500) !important;
  --mdc-checkbox-selected-pressed-state-layer-color: theme(colors.blue.500) !important;
  --mdc-checkbox-unselected-focus-state-layer-color: transparent !important;
  --mdc-checkbox-unselected-hover-state-layer-color: transparent !important;
  --mdc-checkbox-unselected-pressed-state-layer-color: transparent !important;
  --mdc-list-list-item-container-shape: 0 !important;

  --mdc-list-list-item-label-text-weight: 300 !important;
  --mdc-list-list-item-label-text-size: theme('fontSize.sm') !important;

  --mdc-checkbox-state-layer-size: 24px !important;

  --mat-expansion-header-text-size: theme('fontSize.sm');

  --mdc-list-list-item-one-line-container-height: 24px;
  .mat-expansion-panel-header {
  }

  .mat-mdc-list-item {
    /* height: unset !important; */
    @apply flex items-center mb-0.5 p-0 rounded cursor-pointer border-none h-8;
  }

  .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
    margin-left: theme('spacing.2') !important;
    margin-right: 0 !important;
  }

  .mat-mdc-list-item[aria-selected='true'] {
    @apply bg-gray-200;
  }

  .mdc-list-item--with-trailing-checkbox .mdc-list-item__end {
    margin-right: 0;
  }

  .mdc-list-item--with-one-line {
  }

  .mdc-list-item__primary-text {
    --mdc-list-list-item-label-text-weight: 400 !important;
    overflow: visible !important;
  }
}
