/* Progress bar */
.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-height: 4px !important;
  --mdc-linear-progress-track-height: 4px !important;
  --mdc-linear-progress-track-shape: 0;
  --mdc-linear-progress-track-color: theme('colors.gray.100');
}

.mat-gray {
  --mdc-circular-progress-active-indicator-color: theme('colors.gray.300');
  --mdc-linear-progress-active-indicator-color: theme('colors.gray.300');
  --mdc-linear-progress-track-color: theme('colors.gray.200');
}

.mat-red {
  --mdc-circular-progress-active-indicator-color: theme('colors.red.500');
  --mdc-linear-progress-active-indicator-color: theme('colors.red.500');
  --mdc-linear-progress-track-color: theme('colors.gray.200');
}

.mat-orange {
  --mdc-circular-progress-active-indicator-color: theme('colors.orange.500');
  --mdc-linear-progress-active-indicator-color: theme('colors.orange.500');
  --mdc-linear-progress-track-color: theme('colors.gray.200');
}

.mat-orange-500 {
  --mdc-circular-progress-active-indicator-color: theme('colors.orange.500');
  --mdc-linear-progress-active-indicator-color: theme('colors.orange.500');
  --mdc-linear-progress-track-color: theme('colors.gray.200');
}

.mat-orange-600 {
  --mdc-circular-progress-active-indicator-color: theme('colors.orange.600');
  --mdc-linear-progress-active-indicator-color: theme('colors.orange.600');
  --mdc-linear-progress-track-color: theme('colors.gray.200');
}

.mat-orange-700 {
  --mdc-circular-progress-active-indicator-color: theme('colors.orange.700');
  --mdc-linear-progress-active-indicator-color: theme('colors.orange.700');
  --mdc-linear-progress-track-color: theme('colors.gray.200');
}

.mat-orange-800 {
  --mdc-circular-progress-active-indicator-color: theme('colors.orange.800');
  --mdc-linear-progress-active-indicator-color: theme('colors.orange.800');
  --mdc-linear-progress-track-color: theme('colors.gray.200');
}

.mat-yellow {
  --mdc-circular-progress-active-indicator-color: theme('colors.yellow.500');
  --mdc-linear-progress-active-indicator-color: theme('colors.yellow.500');
  --mdc-linear-progress-track-color: theme('colors.gray.200');
}

.mat-yellow-400 {
  --mdc-circular-progress-active-indicator-color: theme('colors.yellow.400');
  --mdc-linear-progress-active-indicator-color: theme('colors.yellow.400');
  --mdc-linear-progress-track-color: theme('colors.gray.200');
}

.mat-yellow-500 {
  --mdc-circular-progress-active-indicator-color: theme('colors.yellow.500');
  --mdc-linear-progress-active-indicator-color: theme('colors.yellow.500');
  --mdc-linear-progress-track-color: theme('colors.gray.200');
}

.mat-green {
  --mdc-circular-progress-active-indicator-color: theme('colors.green.500');
  --mdc-linear-progress-active-indicator-color: theme('colors.green.500');
  --mdc-linear-progress-track-color: theme('colors.gray.200');
}

.mat-green-700 {
  --mdc-circular-progress-active-indicator-color: theme('colors.green.700');
  --mdc-linear-progress-active-indicator-color: theme('colors.green.700');
  --mdc-linear-progress-track-color: theme('colors.gray.200');
}

.mat-green-500 {
  --mdc-circular-progress-active-indicator-color: theme('colors.green.500');
  --mdc-linear-progress-active-indicator-color: theme('colors.green.500');
  --mdc-linear-progress-track-color: theme('colors.gray.200');
}

.mat-green-400 {
  --mdc-circular-progress-active-indicator-color: theme('colors.green.400');
  --mdc-linear-progress-active-indicator-color: theme('colors.green.400');
  --mdc-linear-progress-track-color: theme('colors.gray.200');
}
