:root {
  @include mat.chips-overrides(
    (
      outline-width: 0,
      container-shape-radius: theme('spacing.8'),
      elevated-container-color: theme('colors.gray.100'),
      elevated-selected-container-color: theme('colors.blue.500'),
      selected-focus-state-layer-color: theme('colors.blue.500'),
      elevated-disabled-container-color: theme('colors.gray.200'),
      with-icon-icon-color: theme('colors.gray.600'),
      with-icon-selected-icon-color: theme('colors.white'),
    )
  );
}

.mat-mdc-standard-chip {
}

// .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
//   --mdc-chip-disabled-label-text-color: theme(colors.gray.800);
//   --mdc-chip-elevated-container-color: theme(colors.gray.100);
//   --mdc-chip-elevated-disabled-container-color: theme(colors.gray.200);
//   --mdc-chip-focus-state-layer-color: theme(colors.blue.400);
//   --mdc-chip-focus-state-label-text-color: theme(colors.white);
// }

.mat-mdc-chip.cdk-focused {
  // --mdc-chip-label-text-color: theme(colors.gray.800);
  // --mdc-chip-with-trailing-icon-trailing-icon-color: theme(colors.white);

  .mat-mdc-chip-remove {
    // opacity: 1 !important;
  }

  .mat-mdc-chip-focus-overlay {
    // opacity: 1 !important;
  }
}

.mat-mdc-chip-remove::after {
  margin: unset !important;
  padding: unset !important;
}

.mdc-evolution-chip-set .mdc-evolution-chip {
  margin-left: 4px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  @apply font-medium text-sm leading-none;
}

.struct-simple-select__single-chip-row {
  background-color: transparent !important;
  /* flex: 1; */
}

.struct-simple-select__single-chip-row .mdc-evolution-chip__text-label {
  font-weight: normal !important;
  font-size: unset !important;
}

.struct-simple-select__single-chip-row .mdc-evolution-chip__action {
  justify-content: flex-start !important;
  padding: 0 4px !important;
}

.struct-simple-select__single-chip-row .mdc-evolution-chip__action:before,
.struct-simple-select__single-chip-row .mat-mdc-chip-focus-overlay,
.struct-simple-select__single-chip-row .mat-ripple {
  display: none !important;
}

struct-simple-select {
  .mat-mdc-chip-input {
    flex: 1 0 30px !important;
  }
}

.mat-mdc-chip-action-label {
  struct-avatar {
    @apply ml-0;
  }
}

.mdc-evolution-chip-set__chips {
  @apply gap-1 max-h-48 overflow-y-auto struct-scrollbar;
}

// Material Chips
.mdc-list-item__primary-text {
  @apply w-full;
  overflow: visible !important;
}

.struct-simple-select__multiple-chip-row .mat-mdc-chip {
  margin-left: -8px;
}

.mdc-evolution-chip-set__chips {
  @apply items-center;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 28px;
}

.mdc-list-item__disabled-opacity-full {
  .mdc-list-item__primary-text {
    opacity: 1 !important;
  }
}
