/* Card  */
:root {
  @include mat.card-overrides(
    (
      elevated-container-shape: theme('spacing.1'),
      elevated-container-color: theme('colors.white'),
      elevated-container-elevation: none,
    )
  );
}

.mat-mdc-card {
  /* @apply shadow-md; */
  @apply h-full transition-all duration-100;

  &:hover {
    @apply shadow-xl z-10;
  }

  &.is-selected {
    @apply ring-2 ring-blue-500 shadow-lg;
  }
}

.mat-mdc-card-title {
  --mat-card-title-text-size: 14px;
}

.mdc-card__actions {
  @apply border-t border-gray-100 mt-2 flex gap-2;
}

.mdc-card__content {
  @apply p-4;
}
