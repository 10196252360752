/* Dropdowns */
.mat-mdc-menu-panel {
  /* --mat-menu-item-label-text-font: inherit;
  --mat-menu-item-label-text-line-height: 24px; */
  --mat-menu-item-label-text-size: 12px;
  /* --mat-menu-item-label-text-tracking: inherit;
  --mat-menu-item-label-text-weight: inherit; */
  --mat-menu-container-shape: 0px !important;
  max-width: theme('width.96');
}

.mat-mdc-menu-item {
  min-height: unset !important;
  padding-top: theme('spacing.2') !important;
  padding-bottom: theme('spacing.2') !important;
}

.mat-mdc-menu-item-text {
  @apply flex items-center gap-1 flex-1;
}

.mat-mdc-menu-item .mat-icon {
  margin-right: 0px;
}

.mat-mdc-menu-item .struct-icon {
  margin-right: 16px;
}
