/* Context menu */
.struct-context-menu {
  min-width: 180px;
  max-width: 280px;
  @apply shadow-lg py-1 px-0 flex flex-col bg-white outline-1 outline-gray-100;
}

.struct-context-menu-title {
  user-select: none;
  //min-width: 64px;

  @apply py-0 px-4 flex bg-transparent text-sm font-semibold focus:outline-none leading-9 min-w-min;
}

.struct-context-menu-separator {
  user-select: none;
  //min-width: 64px;

  @apply my-2 h-px bg-gray-100 border-none;
}

.struct-context-menu-item {
  user-select: none;
  //min-width: 64px;

  @apply py-0 px-4 flex flex-row items-center gap-2 flex-1 bg-transparent cursor-pointer border-0 text-sm focus:outline-none leading-9 min-w-min;
}

.struct-context-menu-item:hover {
  @apply bg-gray-50;
}

.struct-context-menu-item:active {
  @apply bg-blue-100;
}
