/*--------------------------------

struct-icon-font icon font
Generated using nucleoapp.com

-------------------------------- */

@font-face {
  font-family: 'struct-icon-font';
  src: url('../fonts/struct-icon-font.eot');
  src: url('../fonts/struct-icon-font.eot') format('embedded-opentype'),
       url('../fonts/struct-icon-font.woff2') format('woff2'),
       url('../fonts/struct-icon-font.woff') format('woff'),
       url('../fonts/struct-icon-font.ttf') format('truetype'),
       url('../fonts/struct-icon-font.svg') format('svg');
}

/* base class */
.struct-icon {
  display: inline-block;
  font: normal normal normal 1em/1 'struct-icon-font';
  color: inherit;
  flex-shrink: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* size examples - relative units */
.struct-icon-sm {
  font-size: 0.8em;
}

.struct-icon-lg {
  font-size: 1.2em;
}

/* size examples - absolute units */
.struct-icon-16 {
  font-size: 16px;
}

.struct-icon-32 {
  font-size: 32px;
}

/* rotate the icon infinitely */
.struct-icon-is-spinning {
  animation: struct-icon-spin 1s infinite linear;
}

@keyframes struct-icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* transform */
.struct-icon-rotate-90 {
  transform: rotate(90deg);
}

.struct-icon-rotate-180 {
  transform: rotate(180deg);
}

.struct-icon-rotate-270 {
  transform: rotate(270deg);
}

.struct-icon-flip-y {
  transform: scaleY(-1);
}

.struct-icon-flip-x {
  transform: scaleX(-1);
}

/* icons */
.struct-icon-variants_generate::before {
  content: "\ea42";
}

.struct-icon-bookmark_add::before {
  content: "\ea5e";
}

.struct-icon-translation::before {
  content: "\ea5f";
}

.struct-icon-bookmark_delete::before {
  content: "\ea60";
}

.struct-icon-revision_log::before {
  content: "\ea61";
}

.struct-icon-quick_actions::before {
  content: "\ea62";
}

.struct-icon-apps::before {
  content: "\ea63";
}

.struct-icon-notifications::before {
  content: "\ea64";
}

.struct-icon-user::before {
  content: "\ea65";
}

.struct-icon-globe::before {
  content: "\ea66";
}

.struct-icon-check::before {
  content: "\ea68";
}

.struct-icon-move_right::before {
  content: "\ea69";
}

.struct-icon-move_up::before {
  content: "\ea6a";
}

.struct-icon-move-down::before {
  content: "\ea6b";
}

.struct-icon-move_left::before {
  content: "\ea6c";
}

.struct-icon-chevron_right::before {
  content: "\ea6d";
}

.struct-icon-chevron_left::before {
  content: "\ea6e";
}

.struct-icon-plus::before {
  content: "\ea6f";
}

.struct-icon-minus::before {
  content: "\ea70";
}

.struct-icon-delete::before {
  content: "\ea71";
}

.struct-icon-insert_source::before {
  content: "\ea72";
}

.struct-icon-desktop::before {
  content: "\ea73";
}

.struct-icon-tablet::before {
  content: "\ea74";
}

.struct-icon-phone::before {
  content: "\ea75";
}

.struct-icon-copy_to_all::before {
  content: "\ea76";
}

.struct-icon-copy::before {
  content: "\ea77";
}

.struct-icon-support::before {
  content: "\ea78";
}

.struct-icon-question-mark::before {
  content: "\ea79";
}

.struct-icon-help::before {
  content: "\ea7a";
}

.struct-icon-google_translate::before {
  content: "\ea7b";
}

.struct-icon-archive::before {
  content: "\ea7c";
}

.struct-icon-remove::before {
  content: "\ea7d";
}

.struct-icon-variant::before {
  content: "\ea7f";
}

.struct-icon-product::before {
  content: "\ea80";
}

.struct-icon-search::before {
  content: "\ea81";
}

.struct-icon-image::before {
  content: "\ea82";
}

.struct-icon-image-stack::before {
  content: "\ea83";
}

.struct-icon-undo::before {
  content: "\ea84";
}

.struct-icon-redo::before {
  content: "\ea85";
}

.struct-icon-translation_revision::before {
  content: "\ea86";
}

.struct-icon-sort::before {
  content: "\ea87";
}

.struct-icon-folder_filled::before {
  content: "\ea88";
}

.struct-icon-folder_filled_open::before {
  content: "\ea89";
}

.struct-icon-move::before {
  content: "\ea8a";
}

.struct-icon-more_horiz::before {
  content: "\ea8b";
}

.struct-icon-more_vert::before {
  content: "\ea8c";
}

.struct-icon-services::before {
  content: "\ea8d";
}

.struct-icon-edit::before {
  content: "\ea8f";
}

.struct-icon-log_out::before {
  content: "\ea90";
}

.struct-icon-my_profile::before {
  content: "\ea91";
}

.struct-icon-tenants::before {
  content: "\ea92";
}

.struct-icon-subscriptions::before {
  content: "\ea93";
}

.struct-icon-subscription_plans::before {
  content: "\ea94";
}

.struct-icon-product_structure::before {
  content: "\ea96";
}

.struct-icon-warning::before {
  content: "\ea97";
}

.struct-icon-equals::before {
  content: "\ea98";
}

.struct-icon-priority_high::before {
  content: "\ea99";
}

.struct-icon-text_increase::before {
  content: "\ea9b";
}

.struct-icon-text_decrease::before {
  content: "\ea9c";
}

.struct-icon-tenant_add::before {
  content: "\ea9d";
}

.struct-icon-tenant_remove::before {
  content: "\ea9e";
}

.struct-icon-service::before {
  content: "\eaa0";
}

.struct-icon-sell::before {
  content: "\eaa1";
}

.struct-icon-subscription_plan::before {
  content: "\eaa2";
}

.struct-icon-subscription::before {
  content: "\eaa3";
}

.struct-icon-account_circle::before {
  content: "\eaa4";
}

.struct-icon-view_column::before {
  content: "\eaa5";
}

.struct-icon-add::before {
  content: "\eaa6";
}

.struct-icon-toggle_off::before {
  content: "\eaa7";
}

.struct-icon-toggle_on::before {
  content: "\eaa8";
}

.struct-icon-drag_indicator::before {
  content: "\eaa9";
}

.struct-icon-view::before {
  content: "\eaaa";
}

.struct-icon-global_lists::before {
  content: "\eaab";
}

.struct-icon-background_tasks::before {
  content: "\eaac";
}

.struct-icon-collections::before {
  content: "\eaad";
}

.struct-icon-import::before {
  content: "\eaae";
}

.struct-icon-catalogues::before {
  content: "\eaaf";
}

.struct-icon-publications::before {
  content: "\eab0";
}

.struct-icon-data_supplier_onboarding::before {
  content: "\eab1";
}

.struct-icon-product_model_setup::before {
  content: "\eab2";
}

.struct-icon-settings::before {
  content: "\eab3";
}

.struct-icon-shopify_connect::before {
  content: "\eab4";
}

.struct-icon-apps_24::before {
  content: "\eab5";
}

.struct-icon-todo_24::before {
  content: "\eab9";
}

.struct-icon-global_lists_32::before {
  content: "\eabb";
}

.struct-icon-catalogues_32::before {
  content: "\eabc";
}

.struct-icon-collections_32::before {
  content: "\eabd";
}

.struct-icon-import_32::before {
  content: "\eabe";
}

.struct-icon-product_model_setup_32::before {
  content: "\eabf";
}

.struct-icon-arrow_left::before {
  content: "\eac2";
}

.struct-icon-arrow_right::before {
  content: "\eac3";
}

.struct-icon-grid_view::before {
  content: "\eac4";
}

.struct-icon-multiple::before {
  content: "\eac5";
}

.struct-icon-comments::before {
  content: "\eac6";
}

.struct-icon-calendar::before {
  content: "\eac7";
}

.struct-icon-attribute_64::before {
  content: "\eac8";
}

.struct-icon-attribute_48::before {
  content: "\eac9";
}

.struct-icon-attribute_32::before {
  content: "\eaca";
}

.struct-icon-attribute_24::before {
  content: "\eacb";
}

.struct-icon-lock::before {
  content: "\eacc";
}

.struct-icon-unlocked::before {
  content: "\eacd";
}

.struct-icon-settings-gear-12::before {
  content: "\eace";
}

.struct-icon-arrow_sm_up::before {
  content: "\ead2";
}

.struct-icon-arrow_sm_down::before {
  content: "\ead3";
}

.struct-icon-arrow_sm_right::before {
  content: "\ead4";
}

.struct-icon-arrow_sm_left::before {
  content: "\ead5";
}

.struct-icon-enlarge::before {
  content: "\ead6";
}

.struct-icon-tasks_16::before {
  content: "\ead7";
}

.struct-icon-shortcut_add::before {
  content: "\eadc";
}

.struct-icon-shortcut_remove::before {
  content: "\eadd";
}

.struct-icon-groups_24::before {
  content: "\eae4";
}

.struct-icon-roles_24::before {
  content: "\eae5";
}

.struct-icon-user_24::before {
  content: "\eae6";
}

.struct-icon-tasks_24::before {
  content: "\eae7";
}

.struct-icon-global_lists_24::before {
  content: "\eae8";
}

.struct-icon-catalogues_24::before {
  content: "\eae9";
}

.struct-icon-product_structure_24::before {
  content: "\eaea";
}

.struct-icon-search_24::before {
  content: "\eaeb";
}

.struct-icon-settings_24::before {
  content: "\eaec";
}

.struct-icon-permission_24::before {
  content: "\eaee";
}

.struct-icon-wand::before {
  content: "\eaef";
}

.struct-icon-trash_48::before {
  content: "\eaf0";
}

.struct-icon-redo_48::before {
  content: "\eaf1";
}

.struct-icon-file_text::before {
  content: "\eaf2";
}

.struct-icon-file_article::before {
  content: "\eaf3";
}

.struct-icon-cut::before {
  content: "\eaf4";
}

.struct-icon-workflow_24::before {
  content: "\eaf8";
}

.struct-icon-enrichmentinsights_24::before {
  content: "\eafa";
}

.struct-icon-dimensions_24::before {
  content: "\eafb";
}

.struct-icon-productmodel_24::before {
  content: "\eafc";
}

.struct-icon-attributegroups_24::before {
  content: "\eafd";
}

.struct-icon-workflow_16::before {
  content: "\eafe";
}

.struct-icon-businessrule_16::before {
  content: "\eaff";
}

.struct-icon-attribute_16::before {
  content: "\eb00";
}

.struct-icon-attributegroups_16::before {
  content: "\eb01";
}

.struct-icon-enrichmentinsights_16::before {
  content: "\eb02";
}

.struct-icon-dimensions_16::before {
  content: "\eb03";
}

.struct-icon-productmodel_16::before {
  content: "\eb04";
}

.struct-icon-permissions_16::before {
  content: "\eb05";
}

.struct-icon-set_as_default::before {
  content: "\eb06";
}

.struct-icon-remove_12::before {
  content: "\eb07";
}

.struct-icon-down_arrow_12::before {
  content: "\eb08";
}

.struct-icon-condition_16::before {
  content: "\eb0a";
}

.struct-icon-condition_24::before {
  content: "\eb0b";
}

.struct-icon-businessrule_24::before {
  content: "\eb0c";
}

.struct-icon-list::before {
  content: "\eb0d";
}

.struct-icon-folder_64::before {
  content: "\eb0e";
}

.struct-icon-move_layer_up::before {
  content: "\eb0f";
}

.struct-icon-move_layer_down::before {
  content: "\eb10";
}

.struct-icon-move_layer_left::before {
  content: "\eb11";
}

.struct-icon-move_layer_right::before {
  content: "\eb12";
}

.struct-icon-clipboard::before {
  content: "\eb13";
}

.struct-icon-folder::before {
  content: "\eb15";
}

.struct-icon-section::before {
  content: "\eb16";
}

.struct-icon-calendar_24::before {
  content: "\eb17";
}

.struct-icon-internal_id_24::before {
  content: "\eb18";
}

.struct-icon-section_24::before {
  content: "\eb19";
}

.struct-icon-folder-2::before {
  content: "\eb1a";
}

.struct-icon-reset_width::before {
  content: "\eb1b";
}

.struct-icon-reset_search::before {
  content: "\eb1c";
}

.struct-icon-table_view::before {
  content: "\eb1d";
}

.struct-icon-unhandled::before {
  content: "\eb1e";
}

.struct-icon-handled::before {
  content: "\eb1f";
}

.struct-icon-maximize::before {
  content: "\eb20";
}

.struct-icon-download::before {
  content: "\eb21";
}

.struct-icon-link::before {
  content: "\eb22";
}

.struct-icon-swap::before {
  content: "\eb23";
}

.struct-icon-aiassistant::before {
  content: "\eb24";
}

.struct-icon-aiassistant_24::before {
  content: "\eb25";
}

.struct-icon-error::before {
  content: "\eb26";
}

.struct-icon-wizard_step_complete::before {
  content: "\eb27";
}

.struct-icon-messages_24::before {
  content: "\eb28";
}

.struct-icon-dashboard::before {
  content: "\eb29";
}

.struct-icon-dam_toolbar_adjust::before {
  content: "\eb2a";
}

.struct-icon-dam_toolbar_crop::before {
  content: "\eb2b";
}

.struct-icon-pin::before {
  content: "\eb2c";
}

.struct-icon-update_sequentially::before {
  content: "\eb2d";
}

.struct-icon-export::before {
  content: "\eb2e";
}

.struct-icon-barcode::before {
  content: "\eb2f";
}

.struct-icon-filter::before {
  content: "\eb31";
}

.struct-icon-merge::before {
  content: "\eb32";
}

.struct-icon-classify::before {
  content: "\eb33";
}

.struct-icon-create_task::before {
  content: "\eb34";
}

.struct-icon-field::before {
  content: "\eb36";
}

.struct-icon-warning_32::before {
  content: "\eb37";
}

.struct-icon-search_advanced::before {
  content: "\eb38";
}

.struct-icon-goto::before {
  content: "\eb39";
}

.struct-icon-c_remove::before {
  content: "\eb3a";
}

.struct-icon-lock_12::before {
  content: "\eb3b";
}

.struct-icon-refresh_12::before {
  content: "\eb3c";
}

.struct-icon-check_12::before {
  content: "\eb3d";
}

.struct-icon-folder_upload_24::before {
  content: "\eb3e";
}

.struct-icon-pending::before {
  content: "\eb3f";
}

.struct-icon-import_24::before {
  content: "\eb40";
}

.struct-icon-check-e::before {
  content: "\eb42";
}

.struct-icon-tasks::before {
  content: "\eb43";
}

.struct-icon-expand::before {
  content: "\eb46";
}

.struct-icon-compare::before {
  content: "\eb47";
}

.struct-icon-collapse::before {
  content: "\eb48";
}

.struct-icon-check_32::before {
  content: "\eb49";
}

.struct-icon-error_32::before {
  content: "\eb4a";
}

.struct-icon-folder_add_64::before {
  content: "\eb4b";
}

.struct-icon-asset_add_64::before {
  content: "\eb4c";
}

.struct-icon-folder_filled_64::before {
  content: "\eb4d";
}

.struct-icon-asset_64::before {
  content: "\eb4e";
}

.struct-icon-cloud_upload::before {
  content: "\eb4f";
}

.struct-icon-asset::before {
  content: "\eb50";
}

.struct-icon-info::before {
  content: "\eb51";
}

.struct-icon-list_64::before {
  content: "\eb52";
}

.struct-icon-shortcut_add_32::before {
  content: "\eb53";
}

.struct-icon-log_message::before {
  content: "\eb54";
}

.struct-icon-dots_anim::before {
  content: "\eb55";
}

.struct-icon-add_widget_24::before {
  content: "\eb56";
}

.struct-icon-add_widget_32::before {
  content: "\eb57";
}

.struct-icon-entities_32::before {
  content: "\eb58";
}

.struct-icon-notes_32::before {
  content: "\eb59";
}

.struct-icon-reorder::before {
  content: "\eb5a";
}

.struct-icon-redirect::before {
  content: "\eb5b";
}

.struct-icon-versions::before {
  content: "\eb5c";
}

.struct-icon-replace::before {
  content: "\eb5d";
}

.struct-icon-hide::before {
  content: "\eb5e";
}

.struct-icon-edit_24::before {
  content: "\eb5f";
}

.struct-icon-refresh_24::before {
  content: "\eb60";
}

.struct-icon-remove_24::before {
  content: "\eb61";
}

.struct-icon-dashboard_save_24::before {
  content: "\eb62";
}

.struct-icon-attribute::before {
  content: "\eb64";
}

.struct-icon-maintenance_24::before {
  content: "\eb65";
}

.struct-icon-tasks_32::before {
  content: "\eb66";
}

.struct-icon-unpin::before {
  content: "\eb67";
}

.struct-icon-webhooks::before {
  content: "\eb68";
}

.struct-icon-api_configuration::before {
  content: "\eb69";
}

.struct-icon-azure_service_bus::before {
  content: "\eb6a";
}

.struct-icon-index_management::before {
  content: "\eb6b";
}

.struct-icon-changelog::before {
  content: "\eb6c";
}

.struct-icon-action_classify::before {
  content: "\eb70";
}

.struct-icon-action_publications::before {
  content: "\eb71";
}

.struct-icon-action_copy::before {
  content: "\eb72";
}

.struct-icon-action_merge::before {
  content: "\eb73";
}

.struct-icon-action_model::before {
  content: "\eb74";
}

.struct-icon-action_create_task::before {
  content: "\eb75";
}

.struct-icon-action_export::before {
  content: "\eb76";
}

.struct-icon-action_unarchive::before {
  content: "\eb77";
}

.struct-icon-action_archive::before {
  content: "\eb78";
}

.struct-icon-internal_id::before {
  content: "\eb79";
}

.struct-icon-calendar_date::before {
  content: "\eb7b";
}

.struct-icon-fixed_value::before {
  content: "\eb7c";
}

.struct-icon-multiply::before {
  content: "\eb7d";
}

.struct-icon-divide::before {
  content: "\eb7e";
}

.struct-icon-select_value::before {
  content: "\eb7f";
}

.struct-icon-variant_groups::before {
  content: "\eb80";
}

.struct-icon-actions::before {
  content: "\eb81";
}

.struct-icon-action_bulk_update::before {
  content: "\eb83";
}

.struct-icon-condition::before {
  content: "\eb84";
}

.struct-icon-arrow_down::before {
  content: "\eb86";
}

.struct-icon-permissions::before {
  content: "\eb87";
}

.struct-icon-permission_groups::before {
  content: "\eb88";
}

.struct-icon-folder_add::before {
  content: "\eb89";
}

.struct-icon-sync::before {
  content: "\eb8a";
}

.struct-icon-lock_64::before {
  content: "\eb8b";
}

.struct-icon-database::before {
  content: "\eb8c";
}

.struct-icon-replay::before {
  content: "\eb8d";
}

.struct-icon-cycle::before {
  content: "\eb8e";
}

.struct-icon-business_unit::before {
  content: "\eb8f";
}

.struct-icon-business_unit_32::before {
  content: "\eb91";
}

.struct-icon-business_unit_24::before {
  content: "\eb92";
}

.struct-icon-ruleset::before {
  content: "\eb93";
}

.struct-icon-topbar_search_advanced::before {
  content: "\eb94";
}
