/* Tab bar */
:root {
  @include mat.tabs-overrides(
    (
      divider-height: 0,
    )
  );
}

.mat-mdc-tab-group {
}

.mat-mdc-tab-nav-bar {
}

.mat-mdc-tab-nav-bar--no-border {
  @apply border-gray-100 border-none;
}

.mat-mdc-tab-nav-bar--border {
  @apply border-gray-200 border-b;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 3.5rem !important;
  --mdc-tab-indicator-active-indicator-height: 3px !important;
  @apply bg-transparent pl-0 h-14;

  // .is-scrolled & {
  //   position: fixed !important;
  //   @apply bg-gray-50 mt-2 top-36 w-full z-20 border-gray-200;
  // }
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0 !important;
}

.mat-mdc-tab-group {
  flex-grow: 1;
  height: 100%;
}

.mat-mdc-tab-body-wrapper {
  flex-grow: 1;
  height: 100%;
}

.mat-mdc-tab-labels {
  @apply space-x-4 sm:space-x-5 px-2 sm:px-3 lg:px-5;
}

.mat-tab-label {
  min-width: unset !important;
  padding: 0 !important;
  opacity: 1 !important;
  @apply font-semibold text-lg text-gray-900;
}

.mat-mdc-tab,
.mdc-tab {
  padding: 0px !important;
  min-width: unset !important;
}

.mdc-tab .mdc-tab__ripple::before {
  display: none !important;
}
.mat-mdc-tab-ripple {
  display: none;
}

.struct-tabs-with-action-buttons .mat-mdc-tab-header {
  @apply mr-14;
}

.mat-mdc-tab-nav-bar {
  @apply px-4;
}

.mat-mdc-tab-links {
  @apply gap-8;
}

.mat-mdc-tab-link {
  --mat-tab-header-label-text-size: 1rem;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-tab-header-label-text-weight: 500;
  @apply text-gray-900 font-semibold;
}

.mat-mdc-tab-link .mdc-tab__ripple::before {
  display: none !important;
}
