@use '@angular/material' as mat;
@use './material_theme-colors.scss' as themeColor;
@include mat.core();

// $struct-typography: mat.define-typography-config(
//   $caption: mat.define-typography-level(11px, 1, 400),
// );

$struct-theme: mat.define-theme(
  (
    color: (
      primary: themeColor.$primary-palette,
      tertiary: themeColor.$tertiary-palette,
      theme-type: light,
    ),
    typography: (
      use-system-variables: true,
      // this here
    ),
  )
);

html {
  @include mat.core-theme($struct-theme);
  @include mat.all-component-themes($struct-theme);
  @include mat.all-component-densities(-4);
  // @include mat.tooltip-typography($struct-typography)
  @include mat.form-field-density(-5);
  // @include mat.datepicker-density(-2);
  @include mat.button-density(-2);
}

@import './material/overrides';
@import './material/tailwind-fixes';
@import './material/autocomplete';
@import './material/buttons';
@import './material/cards';
@import './material/chips';
@import './material/context-menus.scss';
@import './material/datepicker';
@import './material/dialogs';
@import './material/dropdowns';
@import './material/elevation';
@import './material/expansion-panel';
@import './material/form-fields';
@import './material/lists';
@import './material/menu';
@import './material/panels';
@import './material/paginators';
@import './material/progress-bars';
@import './material/steps';
@import './material/switch';
@import './material/slide-toggle';
@import './material/sliders';
@import './material/tabs';
@import './material/timepicker';
@import './material/tooltips';
@import './material/trees';

@import './plugins/tinymce';
@import '/libs/ui/src/lib/assets/fonts/graphik/stylesheet.css';
@import './node_modules/mat-icon-button-sizes/styles/mat-icon-button-sizes';
@import '/node_modules/flag-icons/css/flag-icons.min.css';
@import 'libs/ui/src/lib/assets/fonts/nucleo/css/icons';
$icon-font-path: '/libs/ui/src/lib/assets/fonts/nucleo';

:root {
  --mdc-shape-small: 2px;
  --mat-mdc-form-field-label-offset-x: 0px;
}

html,
body {
  height: 100%;
  font-size: 14px;
  --mdc-filled-button-label-text-size: 12px;
}

.struct-sidebar {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
}

/**
 * ==============================================
 * Dot Falling
 * ==============================================
 */
.dot-falling {
  position: relative;
  left: -9999px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #a1a1aa;
  color: #a1a1aa;
  box-shadow: 9999px 0 0 0 #a1a1aa;
  animation: dot-falling 1s infinite linear;
  animation-delay: 0.1s;
}
.dot-falling::before,
.dot-falling::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-falling::before {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #a1a1aa;
  color: #a1a1aa;
  animation: dot-falling-before 1s infinite linear;
  animation-delay: 0s;
}
.dot-falling::after {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #a1a1aa;
  color: #a1a1aa;
  animation: dot-falling-after 1s infinite linear;
  animation-delay: 0.2s;
}

@keyframes dot-falling {
  0% {
    box-shadow: 9999px -12px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 #a1a1aa;
  }
  100% {
    box-shadow: 9999px 12px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dot-falling-before {
  0% {
    box-shadow: 9984px -12px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9984px 0 0 0 #a1a1aa;
  }
  100% {
    box-shadow: 9984px 12px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dot-falling-after {
  0% {
    box-shadow: 10014px -12px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 10014px 0 0 0 #a1a1aa;
  }
  100% {
    box-shadow: 10014px 12px 0 0 rgba(152, 128, 255, 0);
  }
}

/**
 * ==============================================
 * Dot Falling Small
 * ==============================================
 */
.dot-falling-small {
  position: relative;
  left: -9999px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #a1a1aa;
  color: #a1a1aa;
  box-shadow: 9999px 0 0 0 #a1a1aa;
  animation: dot-falling-small 1s infinite linear;
  animation-delay: 0.1s;
}
.dot-falling-small::before,
.dot-falling-small::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-falling-small::before {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #a1a1aa;
  color: #a1a1aa;
  animation: dot-falling-small-before 1s infinite linear;
  animation-delay: 0s;
}
.dot-falling-small::after {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #a1a1aa;
  color: #a1a1aa;
  animation: dot-falling-small-after 1s infinite linear;
  animation-delay: 0.2s;
}

@keyframes dot-falling-small {
  0% {
    box-shadow: 9999px -12px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 #a1a1aa;
  }
  100% {
    box-shadow: 9999px 12px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dot-falling-small-before {
  0% {
    box-shadow: 9989px -12px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9989px 0 0 0 #a1a1aa;
  }
  100% {
    box-shadow: 9989px 12px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dot-falling-small-after {
  0% {
    box-shadow: 10009px -12px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 10009px 0 0 0 #a1a1aa;
  }
  100% {
    box-shadow: 10009px 12px 0 0 rgba(152, 128, 255, 0);
  }
}

@layer components {
  .struct-scrollbar {
    @apply scrollbar-thin scrollbar-thumb-gray-400 hover:scrollbar-thumb-gray-600 scrollbar-track-gray-100;
  }

  .struct-scrollbar-dark {
    @apply scrollbar-thin scrollbar-thumb-gray-700 hover:scrollbar-thumb-gray-700 scrollbar-track-gray-800;
  }

  .struct-scrollbar-red {
    @apply scrollbar-thin scrollbar-thumb-red-700 hover:scrollbar-thumb-red-700 scrollbar-track-red-800;
  }

  .struct-scrollbar-thin {
    @apply scrollbar-w-1 scrollbar-thumb-gray-400 hover:scrollbar-thumb-gray-600 scrollbar-track-gray-100;
  }

  .struct-sidebar-dialog {
    border-radius: 0px;
    min-width: 400px;
    // height: 100vh;
    //box-shadow: none;
    @apply h-full shadow-2xl;
    padding-top: var(--app-header-height);
    transition: width 0.2s ease;

    .mat-dialog-container {
      border-radius: 0px;
      position: relative;
      display: flex;
      flex-grow: 1;
    }

    .mat-dialog-title {
      flex-grow: 0;
    }

    .mat-dialog-content {
      flex-grow: 1;
      max-height: unset;
    }

    .mat-dialog-actions {
      flex-grow: 0;
    }
  }

  // Dialog right
  .struct-sidebar-dialog-position-left {
    @apply left-0 slide-in-from-left-16;
  }

  .struct-sidebar-dialog-position-right {
    @apply right-0 slide-in-from-right-16;
  }

  .struct-sidebar-dialog-position-center {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  // Dialog widths
  .struct-sidebar-dialog-width-quarter {
    @apply w-full md:w-11/12 2xl:w-1/4 min-w-[600px];
  }
  .struct-sidebar-dialog-width-half {
    @apply w-full md:w-11/12 2xl:w-1/2 min-w-[800px];
  }
  .struct-sidebar-dialog-width-full {
    @apply w-full xl:w-11/12;
  }

  .struct-sidebar-dialog-level-1 {
    // width: calc(50% + 30px) !important;
    width: calc(50% + calc(var(--dialog-level-spacing) * 1));
  }

  .struct-sidebar-dialog-level-2 {
    // width: calc(50% + 60px) !important;
    width: calc(50% + calc(var(--dialog-level-spacing) * 2));
  }

  .struct-sidebar-dialog-level-3 {
    // width: calc(50% + 90px) !important;
    width: calc(50% + calc(var(--dialog-level-spacing) * 3));
  }

  .struct-sidebar-dialog-level-4 {
    // width: calc(50% + 120px) !important;
    width: calc(50% + calc(var(--dialog-level-spacing) * 4));
  }

  .struct-sidebar-dialog-level-5 {
    // width: calc(50% + 150px) !important;
    width: calc(50% + calc(var(--dialog-level-spacing) * 5));
  }

  .struct-sidebar-dialog-level-6 {
    // width: calc(50% + 180px) !important;
    width: calc(50% + calc(var(--dialog-level-spacing) * 6));
  }

  .struct-sidebar-dialog-level-7 {
    // width: calc(50% + 210px) !important;
    width: calc(50% + calc(var(--dialog-level-spacing) * 7));
  }

  .struct-sidebar-dialog-level-8 {
    // width: calc(50% + 240px) !important;
    width: calc(50% + calc(var(--dialog-level-spacing) * 8));
  }

  .struct-sidebar-dialog-level-9 {
    // width: calc(50% + 270px) !important;
    width: calc(50% + calc(var(--dialog-level-spacing) * 9));
  }

  .struct-sidebar-dialog-level-10 {
    // width: calc(50% + 300px) !important;
    width: calc(50% + calc(var(--dialog-level-spacing) * 10));
  }

  .struct-widget {
    @apply relative h-full overflow-hidden flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 hover:scrollbar-thumb-gray-600 scrollbar-track-gray-100;
  }

  .struct-widget-header {
    @apply p-1 flex items-center gap-1 justify-end text-black/50 absolute top-0 left-0 w-full z-10;
  }

  .struct-widget-content {
    @apply h-full p-0 flex-1 overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 hover:scrollbar-thumb-gray-600 scrollbar-track-gray-100;
  }

  struct-form-row-grid-tile {
    &[data-cols='1'] {
      @apply @lg/workarea:col-span-12 @2xl/workarea:col-span-6  @6xl/workarea:col-span-1 @16xl/workarea:col-span-1;
    }
    &[data-cols='2'] {
      @apply @lg/workarea:col-span-12 @2xl/workarea:col-span-6  @6xl/workarea:col-span-2 @16xl/workarea:col-span-2;
    }
    &[data-cols='3'] {
      @apply @lg/workarea:col-span-12 @2xl/workarea:col-span-6  @6xl/workarea:col-span-3 @16xl/workarea:col-span-3;
    }
    &[data-cols='4'] {
      @apply @lg/workarea:col-span-12 @2xl/workarea:col-span-6  @6xl/workarea:col-span-4 @16xl/workarea:col-span-4;
    }
    &[data-cols='5'] {
      @apply @lg/workarea:col-span-12 @2xl/workarea:col-span-6  @8xl/workarea:col-span-5 @16xl/workarea:col-span-5;
    }
    &[data-cols='6'] {
      @apply @lg/workarea:col-span-12 @2xl/workarea:col-span-6  @6xl/workarea:col-span-6 @16xl/workarea:col-span-6;
    }
    &[data-cols='7'] {
      @apply @lg/workarea:col-span-12 @2xl/workarea:col-span-12 @6xl/workarea:col-span-7 @16xl/workarea:col-span-7;
    }
    &[data-cols='8'] {
      @apply @lg/workarea:col-span-12 @2xl/workarea:col-span-12 @6xl/workarea:col-span-8 @16xl/workarea:col-span-8;
    }
    &[data-cols='9'] {
      @apply @lg/workarea:col-span-12 @2xl/workarea:col-span-12 @6xl/workarea:col-span-9 @16xl/workarea:col-span-9;
    }
    &[data-cols='10'] {
      @apply @lg/workarea:col-span-12 @2xl/workarea:col-span-12 @6xl/workarea:col-span-10 @16xl/workarea:col-span-10;
    }
    &[data-cols='11'] {
      @apply @lg/workarea:col-span-12 @2xl/workarea:col-span-12 @6xl/workarea:col-span-11 @16xl/workarea:col-span-11;
    }
    &[data-cols='12'] {
      @apply @lg/workarea:col-span-12 @2xl/workarea:col-span-12 @6xl/workarea:col-span-12 @16xl/workarea:col-span-12;
    }

    @apply col-span-12;
  }
}

.cdk-global-overlay-wrapper:has(> .struct-sidebar-dialog-current) {
  z-index: 1000;
}

:root {
  --dialog-level-spacing: 10px;
}

.cdk-global-overlay-wrapper:has(> .struct-confirm-dialog) {
  z-index: 1000;
}

.struct-dialog-header {
}

.struct-uid-field {
  @apply block py-2 text-base font-medium;
}

.struct-progress-circle-wrap {
  width: 32px;
  height: 32px;
  background: #fff;
  border-radius: 50%;
  border: 0px solid #cdcbd0;
}

.struct-progress-circle-wrap .struct-progress-circle .mask,
.struct-progress-circle-wrap .struct-progress-circle .fill {
  width: 32px;
  height: 32px;
  position: absolute;
  border-radius: 50%;
}

.struct-progress-circle-wrap .struct-progress-circle .mask {
  clip: rect(0px, 32px, 32px, 16px);
}

.struct-progress-circle-wrap .struct-progress-inside-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fff;
  line-height: 24px;
  text-align: center;
  margin-top: 4px;
  margin-left: 4px;
  position: absolute;
  z-index: 100;
}

/* color animation */

/* 3rd progress bar */
.mask .fill {
  clip: rect(0px, 16px, 32px, 0px);
  background-color: #227ded;
}

.mask.full,
.struct-progress-circle .fill {
  animation: fill ease-in-out 3s;
  transform: rotate(70deg);
}

@keyframes fill {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(70deg);
  }
}

// $dts-primary: $struct-color-blue-50;
// $box-shadow: false;
// $selected-item-border-color: $struct-color-blue-50;

[hidden] {
  display: none !important;
}

// Accordion
.struct-accordion-item {
  @apply bg-white rounded flex flex-col mb-1 outline-none focus:outline-none;

  &[aria-expanded='true'] {
    @apply mb-4;
  }
}

.struct-accordion-header {
  @apply px-4 py-2 flex items-center;

  .struct-accordion-header-title {
    @apply font-medium text-xl cursor-pointer h-20 flex-1 flex-shrink-0 flex items-center;
  }

  .struct-accordion-header-description {
    @apply font-normal text-sm text-gray-500 flex-grow-0;
  }
}

.struct-accordion-body {
  @apply px-4 py-4;
}

// splitter
.as-split-gutter {
  width: 100%;
  height: 100%;
  background-color: #f4f4f5 !important;
  position: relative;
  z-index: 9;
  transition: background-color 0.3s;

  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 6px;
    background: #e4e4e7;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: -6px;
    transition: opacity 0.3s;
  }
  &:after {
    content: '';
    display: block;
    height: 100%;
    width: 6px;
    background: #e4e4e7;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 1px;
    transition: opacity 0.3s;
  }

  &:hover {
    background-color: #d4d4d8 !important;

    &:before,
    &:after {
      opacity: 0.25;
    }
  }
}

.as-split-gutter-icon {
  height: 100%;
  width: 100%;
  background-color: black;
  transition: opacity 0.3s;
  opacity: 0;
  position: absolute;

  .dragged &,
  &:hover {
    opacity: 0.1;
  }

  $shadeSize: 8px;

  .as-horizontal & {
    width: 1 + ($shadeSize * 2);
    left: -$shadeSize;
    right: $shadeSize;
  }

  .as-vertical & {
    height: 1 + ($shadeSize * 2);
    top: -$shadeSize;
    bottom: $shadeSize;
  }
}
