/* Struct Picker */
.mat-mdc-form-field-type-struct-picker .mdc-line-ripple,
.mat-mdc-form-field-type-struct-picker .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.mat-mdc-form-field-type-struct-picker .mdc-text-field {
  padding: 0;
}

.mat-mdc-form-field-type-struct-picker .mat-mdc-form-field-infix {
  padding: 0 !important;
}

/* Material Stepper */
.mat-stepper-vertical,
.mat-stepper-horizontal {
  --mat-stepper-container-color: transparent;
  --mat-stepper-header-focus-state-layer-color: transparent;
  --mat-stepper-header-hover-state-layer-color: transparent;
  --mat-stepper-header-icon-background-color: theme('colors.gray.200');
  --mat-stepper-header-selected-state-icon-background-color: theme('colors.blue.500');
  --mat-stepper-header-edit-state-icon-background-color: theme('colors.gray.200');
  /* --mat-stepper-header-done-state-icon-background-color: #2e6660;
  --mat-stepper-header-edit-state-icon-background-color: #2e6660; */
  --mat-stepper-line-color: theme('colors.gray.200');
  --mat-stepper-line-height: 2px;

  .mat-ripple {
    display: none !important;
  }
}

.struct-stepper__no-padding {
  .mat-horizontal-content-container {
    padding: 0 !important;
    overflow: visible !important;
  }
}

.mat-horizontal-stepper-content.mat-horizontal-stepper-content-inactive {
  overflow: visible !important;
}

.mat-step-icon {
  height: 32px !important;
  width: 32px !important;

  // @apply ring-4 ring-gray-100 relative;

  &.mat-step-icon-selected {
    @apply ring-4 ring-blue-200/50;

    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      height: 52px;
      width: 52px;
      border-radius: 50%;
      z-index: -1;
      animation: step-selected-pulsate 1.5s ease infinite;
      // opacity: 0.5;
    }
  }

  &.mat-step-icon-state-edit {
    @apply ring-4 ring-gray-50;
  }
}

.mat-vertical-content-container {
  margin-left: 24px !important;
}

.mat-vertical-stepper-header {
  padding: calc((var(--mat-stepper-header-height) - 24px) / 2) 0.5rem !important;
}

.struct-step-icon {
  @apply flex bg-gray-200 w-2 h-2 rounded-full relative;

  &:before {
    content: '';
    z-index: -1;
    @apply absolute w-1 h-full top-0 bg-gray-300;
  }

  // &:after {
  //   content: '';
  //   z-index: 2;
  //   // opacity: 0.5;
  //   @apply absolute w-2 h-2 bg-blue-500 rounded-full;
  // }

  // @apply ring-4 ring-gray-100 relative;

  &.struct-step-icon-selected {
    @apply ring-4 ring-blue-200/50 bg-blue-500 z-10;

    &:before {
      content: '';
      z-index: -1;
      animation: step-selected-pulsate 1.5s ease infinite;
      // opacity: 0.5;
      @apply absolute w-8 h-8 -m-3 rounded-full;
    }

    &:after {
      content: '';
      z-index: 2;
      // opacity: 0.5;
      @apply absolute w-2 h-2 bg-blue-500 rounded-full;
    }
  }
}

@keyframes step-selected-pulsate {
  0% {
    transform: scale(0.75);
    background-color: #ffffff;
    opacity: 1;
  }
  100% {
    transform: scale(1.1);
    background-color: theme('colors.blue.400');
    opacity: 0;
  }
}

.struct-step-wizard-list {
}

.struct-step-wizard-list-item {
  &:last-child {
    .struct-step-wizard-list-item-link {
      &:before {
        @apply h-1/2;
      }
    }
  }
}

.struct-step-wizard-list-item-link {
  @apply flex items-center space-x-4 py-4 px-4 cursor-pointer relative;

  &:before {
    content: '';
    // opacity: 0.5;
    @apply absolute top-0 left-8 w-px ml-px h-full bg-gray-200;
  }
}

.struct-step-wizard-list-item-dot {
  @apply flex-shrink-0 w-10 flex justify-center relative;
}

.struct-step-wizard-list-item-dot-content {
  @apply flex bg-gray-100 border border-gray-400 ring-4 ring-gray-50 w-2 h-2 rounded-full relative -ml-1;

  &.is-selected {
    @apply ring-4 ring-blue-200/50 bg-blue-500 z-10 border-0;

    &:before {
      content: '';
      z-index: -1;
      animation: step-selected-pulsate 1.5s ease infinite;
      // opacity: 0.5;
      @apply absolute w-8 h-8 -m-3 rounded-full;
    }

    &:after {
      content: '';
      z-index: 2;
      // opacity: 0.5;
      @apply absolute w-2 h-2 bg-blue-500 rounded-full;
    }
  }

  &.is-done {
    @apply ring-4 ring-blue-200/50 bg-blue-700 z-10 border-0;
  }
}

.struct-adapt-stepper {
  @apply flex flex-col flex-grow h-full w-full;

  .mat-horizontal-stepper-wrapper {
    @apply flex flex-col flex-grow w-full h-full;

    .mat-horizontal-stepper-header-container {
      @apply flex-grow-0;
    }

    .mat-horizontal-content-container {
      @apply flex-grow w-full h-full relative;

      .mat-horizontal-stepper-content {
        @apply flex-grow w-full max-h-full;
      }
    }
  }
}
