/* Snack bar */
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  /* --mdc-snackbar-container-color: #fff; */
  --mdc-snackbar-supporting-text-color: #333;
  --mdc-snackbar-container-shape: 0rem;
  /* box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important; */
  /* box-shadow: 0px 0px 8px -1px rgba(0, 0, 0, 0.1) !important; */
  box-shadow: none !important;
}

.mat-mdc-snack-bar-container {
  margin: 0 !important;
  @apply w-96 p-0;
}

.mdc-snackbar__label {
  padding: 0 !important;
}

.mdc-snackbar__surface {
  padding: 0 !important;
}

:root {
  --mdc-typography-body1-font-size: 12px;
}

/* Select box */
.mat-mdc-option {
  --mat-option-label-text-size: 12px;
  --mat-option-hover-state-layer-color: theme(colors.gray.200);
  --mat-option-focus-state-layer-color: theme(colors.gray.200);
  --mat-option-selected-state-layer-color: theme(colors.blue.500);
  --mat-option-selected-state-label-text-color: theme(colors.white);
  min-height: 32px !important;
}

.mat-mdc-option > .mdc-evolution-chip-set {
  margin-left: -8px;
}

// .cdk-drag-preview {
//   @apply bg-white rounded flex flex-row items-center -m-4 p-4 shadow-2xl flex-wrap w-full max-w-xl max-h-24 overflow-hidden relative;
//   height: auto !important;
//   box-sizing: border-box;

//   &::before {
//     content: '';
//     @apply flex items-center justify-center absolute inset-0 bg-gradient-to-b from-white/50 to-white z-10;
//   }

//   &::after {
//     content: 'Drop item';
//     @apply absolute top-2 right-2 text-gray-700 font-medium py-2 px-4 rounded-full bg-gray-50 z-20 text-xs;
//   }
// }

// .cdk-drag-placeholder {
//   @apply bg-gray-50 border-gray-200 border-none w-full h-16 my-2 shadow-inner;

//   &::after {
//     content: 'Drop here';
//     @apply flex items-center justify-end absolute inset-0 text-gray-500 font-medium px-4;
//   }
// }

// .cdk-drag-animating {
//   transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
// }

// .struct-draggable-list.cdk-drop-list-dragging .struct-draggable-list-item:not(.cdk-drag-placeholder) {
//   transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
// }
