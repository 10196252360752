:root {
  @include mat.button-overrides(
    (
      filled-container-shape: theme('spacing.1'),
      filled-container-color: theme('colors.gray.200'),
      filled-label-text-color: theme('colors.gray.600'),
      filled-horizontal-padding: theme('spacing.4'),
      outlined-outline-width: 1px,
      outlined-outline-color: theme('colors.gray.400'),
      outlined-horizontal-padding: theme('spacing.4'),
      text-horizontal-padding: theme('spacing.4'),
      text-container-shape: 2px,
      text-label-text-size: theme('fontSize.sm'),
      text-label-text-color: theme('colors.gray.600'),
      text-state-layer-color: theme('colors.gray.200'),
      text-ripple-color: theme('colors.gray.300'),
    )
  );
  @include mat.icon-button-overrides(
    (
      icon-color: inherit,
    )
  );
  @include mat.button-toggle-overrides(
    (
      height: 28px,
      shape: 2px,
      legacy-height: 28px,
      legacy-shape: 2px,
      legacy-text-color: theme('colors.gray.700'),
      legacy-state-layer-color: theme('colors.black'),
      legacy-disabled-selected-state-background-color: theme('colors.gray.300'),
      background-color: theme('colors.gray.100'),
      selected-state-text-color: theme('colors.gray.900'),
      divider-color: theme('colors.gray.200'),
      disabled-state-text-color: theme('colors.gray.300'),
      disabled-selected-state-background-color: theme('colors.gray.200'),
      disabled-selected-state-text-color: rgba(0, 0, 0, 0.87),
    )
  );
}

.mdc-button__label {
  @apply inline-flex items-center gap-2 truncate line-clamp-1 font-semibold;
}

// .mat-mdc-unelevated-button[disabled][disabled] {
//   // --mdc-filled-button-disabled-container-color: theme('colors.gray.100');
//   // --mdc-filled-button-disabled-label-text-color: theme('colors.gray.300');
// }

.mdc-button {
  &.mat-primary {
    --mdc-filled-button-container-color: theme('colors.blue.500');
    --mdc-filled-button-label-text-color: theme(colors.white);
  }

  &.mat-success {
    --mdc-filled-button-container-color: theme('colors.green.600');
    --mdc-filled-button-label-text-color: theme(colors.white);
  }

  &.mat-dark {
    --mdc-filled-button-container-color: theme('colors.gray.500');
    --mdc-filled-button-label-text-color: theme(colors.white);
  }

  &.mat-transparent {
    --mdc-filled-button-container-color: transparent;
    --mdc-filled-button-label-text-color: theme(colors.gray.700);
  }

  &.mat-warning {
    --mdc-filled-button-container-color: theme('colors.yellow.600');
    --mdc-filled-button-label-text-color: theme(colors.white);
  }

  &.mat-danger {
    --mdc-filled-button-container-color: theme('colors.red.600');
    --mdc-filled-button-label-text-color: theme(colors.white);
  }

  &.reset-width {
    min-width: unset !important;
  }
}

.mat-button-toggle-group {
  @apply border-0  justify-start;
  border: 0 !important;

  --mat-legacy-button-toggle-height: 28px;
  --mat-standard-button-toggle-height: 32px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;

  --mat-standard-button-toggle-text-color: theme('colors.gray.700');
  --mat-standard-button-toggle-background-color: theme('colors.gray.100');
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: theme('colors.gray.300');
  --mat-standard-button-toggle-selected-state-text-color: theme('colors.gray.900');
  --mat-standard-button-toggle-disabled-state-text-color: theme('colors.gray.300');
  --mat-standard-button-toggle-disabled-state-background-color: theme('colors.gray.200');
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: theme('colors.gray.200');
  --mat-standard-button-toggle-divider-color: theme('colors.gray.200');

  .mat-button-toggle {
    @apply flex items-center justify-center;

    .mat-button-toggle-label-content {
      height: var(--mat-standard-button-toggle-height);
      padding: 0 theme('spacing.2') !important;
      @apply inline-flex text-sm font-medium items-center;
    }
  }

  &.struct-toggle-loose {
    .mat-button-toggle {
      .mat-button-toggle-label-content {
        padding: 0 theme('spacing.5') !important;
      }
    }
  }
}

.mat-button-toggle-checkbox-wrapper {
  display: none !important;
}

.mat-button-toggle-checked .mat-button-toggle-button:has(.mat-button-toggle-checkbox-wrapper) {
  padding-left: 0px !important;
}
