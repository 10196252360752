:root {
  @include mat.form-field-overrides(
    (
      container-text-size: theme('fontSize.base'),
      outlined-label-text-size: theme('fontSize.base'),
      outlined-outline-color: theme('colors.gray.300'),
      // container-vertical-padding: theme('spacing.1'),
        subscript-text-size: theme('fontSize.xs'),
      // subscript-text-color: theme('colors.gray.500'),
        container-height: 40px,
    )
  );
}

/* Form fields  */
.mat-mdc-form-field {
  --mdc-filled-text-field-container-color: transparent;
  // --mdc-outlined-text-field-label-text-color: #e0e0e0;
  @apply w-full;
}

.mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
  /* --mat-mdc-form-field-label-offset-x: -4px !important; */
}

.mat-mdc-form-field-icon-suffix {
  @apply pr-4;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  @apply bg-transparent;
}

// .mdc-text-field {
//   &.mdc-text-field--filled {
//     .mat-mdc-floating-label {
//       top: 15px;
//       &.mdc-floating-label--float-above {
//         top: 12px;
//       }
//     }
//   }

//   &.mdc-text-field--outlined {
//     .mat-mdc-floating-label {
//       top: 19px;
//       &.mdc-floating-label--float-above {
//         top: 25px;
//       }
//     }
//   }
// }

.mdc-text-field {
  overflow: visible !important;
}

.mat-mdc-form-field-infix,
.mat-mdc-form-field-flex {
  @apply flex items-center flex-grow pl-0 w-full h-full;
}

.mdc-floating-label,
.mat-mdc-form-field-infix {
  // @apply text-sm;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  @apply border-gray-300;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  @apply border-gray-400;
}

.mdc-notched-outline__notched {
  // border-color: theme(colors.blue.500) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  @apply bg-transparent;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.2);
}

.mdc-text-field--outlined .mdc-floating-label {
}

/* Remove the overlay */
.mat-mdc-form-field-focus-overlay {
  display: none !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: flex !important;
}
