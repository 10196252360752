.mat-elevation-z1 {
  @apply shadow-sm;
}

.mat-elevation-z2 {
  @apply shadow-md;
}

.mat-elevation-z3 {
  @apply shadow;
}

.mat-elevation-z4 {
  @apply shadow-lg;
}

.mat-elevation-z8,
.mat-mdc-elevation-specific.mat-elevation-z8 {
  @apply shadow-lg;
}
