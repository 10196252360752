/* Expansion panels  */
.mat-expansion-panel {
  --mat-expansion-header-text-size: 1.125rem;
  --mat-expansion-container-shape: 0.375rem;
  --mat-expansion-header-collapsed-state-height: theme('spacing.10');
  --mat-expansion-header-expanded-state-height: theme('spacing.12');
  //border-radius: 0 !important;
  box-shadow: none !important;

  .mat-expansion-panel-header {
    // padding-left: 1.25rem !important;
    // padding-right: 1.25rem !important;
    @apply px-4;
  }

  .mat-expansion-panel-header-title {
    @apply font-medium shrink-0 flex-grow flex gap-2 w-full overflow-hidden pr-4;
    flex-basis: unset;
  }

  .mat-expansion-panel-header-description {
    @apply text-sm text-gray-500;
  }

  .mat-expansion-indicator {
    &:before,
    &:after {
      border-color: var(--mat-expansion-header-indicator-color) !important;
    }
  }

  .mat-content {
    @apply flex-col lg:flex-row;
  }

  .mat-expansion-panel-body {
    @apply p-4 pt-0;
  }
}

.mat-expansion-panel-spacing {
  margin: 0 0 theme('spacing.2') 0 !important;
}

.expansion-panel__sidebar .mat-expansion-panel {
  @apply bg-gray-100;
}

.expansion-panel__transparent .mat-expansion-panel {
  @apply bg-transparent;
}

.expansion-panel__no-body-padding .mat-expansion-panel-body {
  @apply p-0;
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
  background: none !important;
}

.expansion-panel__dark {
  --mat-expansion-header-indicator-color: theme(colors.gray.200) !important;

  .mat-expansion-panel {
    @apply bg-gray-800 text-gray-100;

    .mat-expansion-panel-header {
      @apply text-gray-200;

      .mat-expansion-panel-header-title {
        @apply text-gray-200;
      }
    }
  }
}

.expansion-panel__folders {
  --mat-expansion-header-indicator-color: theme(colors.gray.800) !important;

  .mat-expansion-panel {
    @apply bg-gray-100;

    .mat-expansion-panel-header {
      @apply text-gray-800;

      .mat-expansion-panel-header-title {
        @apply text-gray-800;
      }
    }
  }
}

.expansion-panel__condensed {
  .mat-expansion-panel-header {
    --mat-expansion-header-collapsed-state-height: 2rem !important;
  }
}

.mat-expansion-panel:not([class*='mat-elevation-z']) {
  @apply shadow-none;
}
