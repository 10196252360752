/* Dialog */
.mat-mdc-dialog-container .mdc-dialog__surface {
  --mdc-snackbar-container-shape: 0rem;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit !important;
}
