/* mdc switch */

:root {
  @include mat.slide-toggle-overrides(
    (
      // track-height: 28px,
        // // track-width: 44px,
        //   track-shape: 2px,
        // handle-height: 24px,
        // handle-width: 24px,
        // handle-shape: 2px,
    )
  );
}

// .mdc-switch {
//   --mdc-switch-track-width: 44px !important;
//   --mdc-switch-track-height: 22px !important;
//   --mdc-switch-track-shape: 2px !important;
//   --mdc-switch-handle-width: 22px !important;
//   --mdc-switch-handle-height: 22px !important;
//   --mdc-switch-handle-shape: 2px !important;
//   --mdc-switch-unselected-track-color: theme(colors.gray.200) !important;
//   --mdc-switch-unselected-hover-track-color: theme(colors.gray.300) !important;
//   --mdc-switch-unselected-focus-track-color: theme(colors.gray.300) !important;
//   --mdc-switch-unselected-pressed-track-color: theme(colors.gray.300) !important;
//   --mdc-switch-unselected-handle-color: #fff !important;
//   --mdc-switch-unselected-hover-handle-color: #fff !important;
//   --mdc-switch-unselected-focus-handle-color: #fff !important;
//   --mdc-switch-unselected-pressed-handle-color: #fff !important;
//   --mdc-switch-selected-track-color: theme(colors.blue.500) !important;
//   --mdc-switch-selected-hover-track-color: theme(colors.blue.500) !important;
//   --mdc-switch-selected-focus-track-color: theme(colors.blue.500) !important;
//   --mdc-switch-selected-pressed-track-color: theme(colors.blue.500) !important;
//   --mdc-switch-selected-handle-color: #fff;
//   --mdc-switch-selected-hover-handle-color: #fff;
//   --mdc-switch-selected-focus-handle-color: #fff;
//   --mdc-switch-selected-pressed-handle-color: #fff;
//   /* --mdc-switch-selected-hover-handle-color: #ffffff;
//   --mdc-switch-selected-focus-handle-color: #ffffff;
//   --mdc-switch-selected-pressed-handle-color: #ffffff; */
//   --mdc-switch-selected-icon-color: theme(colors.blue.500);
//   --mdc-switch-disabled-selected-icon-color: theme(colors.gray.400);
//   --mdc-switch-disabled-unselected-icon-color: theme(colors.gray.400);
//   --mdc-switch-unselected-icon-color: theme(colors.gray.400);
//   --mdc-switch-unselected-icon-size: 0px !important;
//   --mdc-switch-selected-icon-size: 0px !important;
//   --mdc-switch-handle-elevation-shadow: none !important;

//   @apply ring-2 ring-gray-200 rounded-sm overflow-hidden relative;

//   .mat-mdc-slide-toggle-checked & {
//     @apply ring-blue-500;
//   }
// }

.mat-mdc-slide-toggle {
  @apply w-full h-full flex items-center;

  .mdc-label {
    @apply pl-2;
  }

  &.struct-slide-toggle-inline {
    @apply border border-gray-400 p-2 rounded h-[40px];

    .struct-slide-toggle-on {
      @apply left-2;
    }
  }

  .struct-slide-toggle-on {
    @apply hidden absolute left-0 top-0 w-[20px] h-full items-center justify-center text-[9px] font-semibold text-blue-50 animate-out slide-out-to-bottom-4;
  }

  &.mat-mdc-slide-toggle-checked {
    .struct-slide-toggle-on {
      @apply flex animate-in slide-in-from-bottom-4;
    }
  }
}
